import { JWebEnums } from '../../../services/JWeb';
import { GenericLogMethod } from '../types';

export function genericLogMethod({
  params,
  loggerProps,
  ...defaultParams
}): GenericLogMethod {
  const { jweb, shouldCallBrowserLog, shouldCallNativeLog } = loggerProps;
  // Initializing the log method

  // 1. Create Params to be shown

  // a. Here we get variables get in initialization time

  const defaultParamsList = {};
  params.forEach((param) => {
    if (defaultParams[param]) {
      defaultParamsList[param] = defaultParams[param];
    }
  });

  const logPrinter = (log: Record<string, any>): void => {
    if (shouldCallNativeLog) {
      const nativeLogArgs = (() => {
        const logLevel = (() => {
          switch (log.level) {
            case 'debug':
              return JWebEnums.LogLevel.Debug;
            case 'error':
              return JWebEnums.LogLevel.Error;
            case 'warn':
              return JWebEnums.LogLevel.Warn;
            case 'log':
              return JWebEnums.LogLevel.Log;
          }
        })();

        const message = JSON.stringify(log);

        return {
          logLevel,
          message,
          tag: ''
        };
      })();

      jweb?.Plugins?.Console?.log?.(nativeLogArgs);
    }

    if (shouldCallBrowserLog) {
      console?.[log.level]?.(JSON.stringify(log));
    }
  };

  // Return the log method
  return function (...userParams: any[]) {
    // Here we get variables get in real time
    const timestamp = new Date().toISOString();

    // b. Here we get variables get in runtime
    const runtimeParamList = {};
    let customUserParams = userParams;

    customUserParams.forEach((paramObj) => {
      params.forEach((param) => {
        if (paramObj[param]) {
          runtimeParamList[param] = paramObj[param];
          delete paramObj[param];
        }
      });
    });

    // Filter out empty objects from customUserParams
    customUserParams = customUserParams.filter(
      (paramObj) => Object.keys(paramObj).length > 0
    );

    // Here we create the message
    const log = {
      timestamp,
      ...defaultParamsList,
      ...runtimeParamList,
      data: customUserParams
    };

    // Finally, here we print the message on the right environment
    logPrinter(log);
  };
}
