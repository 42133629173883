import { getServices } from '../../infra/commonInitializer';
import { InterfacesType } from '../../interface';
import { isNative as isNativeFromJWeb, getJWeb } from '../../services/JWeb';
import InitializeDeprecatedServices from './initializeDeprecatedServices';
import { StorageLocale, localeSessionRepository } from './storageLocale';
import UseLocaleContext from './useLocaleContext';
import UseLoggedInRules from './useLoggedInRules';
import UseIdleLogoutTimer from './useIdleLogoutTimer';

import InitBackgroundTaskManager from './InitBackgroundTaskManager';
import InitShellCallInterfaceNavigationPush from './InitClientosNavigationPushEventHandler';
import SyncAuthenticationDataOnJWebValueStore from './SyncAuthenticationDataOnJWebValueStore';

export default class AfterInitBehaviors {
  public static init = async function (
    interfaces: InterfacesType
  ): Promise<void> {
    const isNative = await isNativeFromJWeb();
    const { orgSelector, navigation } = interfaces.v1;

    const {
      tenantHandlerService,
      sessionService,
      navigationService,
      localizationService,
      webServiceRouting,
      idleLogoutService,
      logoutService,
      loginService,
      backgroundTaskManagerService,
      eventService,
      userService
    } = getServices();

    const JWeb = await getJWeb();

    const useLoggedInRules = new UseLoggedInRules({
      navigationService,
      sessionService,
      tenantHandlerService
    });

    const useLocaleContext = new UseLocaleContext({
      navigationService,
      localizationService,
      webServiceRouting
    });

    const useIdleLogoutTimer = new UseIdleLogoutTimer({
      sessionService,
      loginService,
      idleLogoutService,
      logoutService
    });

    const initBackgroundTaskManager = new InitBackgroundTaskManager({
      backgroundTaskManagerService
    });

    const initClientosNavigationPushEvent =
      new InitShellCallInterfaceNavigationPush({
        navigation,
        eventService
      });

    const syncAuthenticationDataOnJWebValueStore =
      new SyncAuthenticationDataOnJWebValueStore({ userService, JWeb });

    await initClientosNavigationPushEvent.init();

    initBackgroundTaskManager.init();

    await useLocaleContext.init();

    useIdleLogoutTimer.init();

    !isNative &&
      (await StorageLocale?.init?.({
        navigationService,
        localizationService,
        localeSessionRepository
      }));

    await InitializeDeprecatedServices?.init?.({
      sessionService,
      orgSelector,
      isNative
    });

    isNative && (await syncAuthenticationDataOnJWebValueStore?.init());
    await useLoggedInRules?.init?.();
  };
}
