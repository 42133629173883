import bindAllMethods from '../../utils/bindAllMethods';
import {
  LoggerInputType,
  SetServiceDependencies
} from '../../infra/commonInitializer/types';
import IApplicationService from '../applicationService/IApplicationService';
import { getJWeb } from '../JWeb';
import { Stack } from '../../types/stratus';
import DebugLogger from './loggerTypes/debugLogger';
import ErrorLogger from './loggerTypes/errorLogger';
import InfoLogger from './loggerTypes/infoLogger';
import WarnLogger from './loggerTypes/warnLogger';
import { GenericLogMethod, LevelLogProps } from './types';
import { shouldCallLogs } from './utils/shouldCallLogs';

class LoggerService {
  public infoLogger: Record<string, GenericLogMethod>;
  public debugLogger: Record<string, GenericLogMethod>;
  public warnLogger: Record<string, GenericLogMethod>;
  public errorLogger: Record<string, GenericLogMethod>;
  private _enabled: boolean;
  private _isNative: boolean;
  private _disableBrowserConsoleOnNative: boolean;
  private _applicationService: IApplicationService;

  constructor(loggerProps: LoggerInputType) {
    this._enabled = loggerProps?.enable ?? true;
    this._isNative = loggerProps?.isNative ?? false;
    this._disableBrowserConsoleOnNative =
      loggerProps?.disableBrowserConsoleOnNative ?? false;
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { applicationService } = services;
    this._applicationService = applicationService;
    bindAllMethods(this);
  }

  async init(): Promise<void> {
    const jweb = await getJWeb();

    const { shouldCallNativeLog, shouldCallBrowserLog } = shouldCallLogs(
      this._isNative,
      jweb,
      this._disableBrowserConsoleOnNative
    );

    //TODO: Add App Version props to the logger
    const levelLoggerProps: LevelLogProps = {
      jweb,
      shouldCallNativeLog,
      shouldCallBrowserLog,
      portalName: this._applicationService.getAppName(),
      serviceName: '@clientos/commons-northbound-api',
      environment: this._applicationService.getApplicationContext(),
      stack: Stack[this._applicationService.getPortalStack()],
      authenticationProvider:
        this._applicationService.getAuthenticationProvider()
    };

    if (this._enabled) {
      this.infoLogger = new InfoLogger(levelLoggerProps).getLogs();
      this.debugLogger = new DebugLogger(levelLoggerProps).getLogs();
      this.warnLogger = new WarnLogger(levelLoggerProps).getLogs();
      this.errorLogger = new ErrorLogger(levelLoggerProps).getLogs();
    }
  }

  getLogProxy(): Record<string, GenericLogMethod> {
    const combinedLogger = {
      ...this.infoLogger,
      ...this.warnLogger,
      ...this.errorLogger,
      ...this.debugLogger
    };
    return new Proxy(combinedLogger, {
      get(target, prop) {
        if (prop in target) {
          return target[String(prop)];
        } else {
          return function (prop) {
            console.warn(`Log ID ${String(prop)} not found.`);
          };
        }
      }
    });
  }
}

export default LoggerService;
