import { GenericLogMethod, ILevelLogClass, LevelLogProps } from '../types';
import { genericLogMethod } from '../utils/genericLogMethod';
import { errorDictionary } from '../Dictionaries/ErrorDictionary';

class ErrorLogger implements ILevelLogClass {
  private _errorLogs: Record<string, GenericLogMethod> = {};

  constructor(loggerProps: LevelLogProps) {
    errorDictionary.forEach((log) => {
      const _method = genericLogMethod({
        ...log,
        loggerProps,
        serviceName: loggerProps.serviceName,
        portalName: loggerProps.portalName,
        environment: loggerProps.environment,
        stack: loggerProps.stack,
        authenticationProvider: loggerProps.authenticationProvider
      });
      this._errorLogs[log.id] = _method;
    });
  }

  getLogs(): Record<string, GenericLogMethod> {
    return this._errorLogs;
  }
}
export default ErrorLogger;
