import 'reflect-metadata';
import { container } from 'tsyringe';
import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';
import { ABTestingService } from '../../services/ABTestingService/ABTestingService';
import { AnalyticsService } from '../../services/AnalyticsService';
import AppContext from '../../services/appContext/AppContext';
import {
  OnecloudApplicationService,
  StratusApplicationService
} from '../../services/applicationService';
import { AuthProviderService } from '../../services/authProviderService';
import { AuthTokenService } from '../../services/authTokenService';
import { GetAccessTokenService } from '../../services/authTokenService/getAcessTokenService';
import { BackgroundTaskManagerService } from '../../services/backgroundTaskService';
import { SystemJSAssetLoaderService } from '../../services/backgroundTaskService/assetLoader';
import { BackgroundTaskService } from '../../services/backgroundTaskService/backgroundTask';
import BreadcrumbService from '../../services/breadcrumbService';
import { ConsentService } from '../../services/consentService';
import { WebCryptoApiEncryptService } from '../../services/encryptService';
import NativeEventService from '../../services/eventService/nativeEventService/NativeEventService';
import WebEventService from '../../services/eventService/webEventService/WebEventService';
import { LaunchDarklyFeatureFlagService } from '../../services/featureFlagService';
import { GrantService } from '../../services/grants/GrantService';
import { GrantHistoryService } from '../../services/grants/grantsHistory';
import { GraphQLPlatform } from '../../services/graphQLService/enums';
import GraphQLService from '../../services/graphQLService/GraphQLService';
import NativeGraphQLService from '../../services/graphQLService/NativeGraphQLService';
import { IdleLogoutService, IdleService } from '../../services/IdleService';
import { LayoutsService } from '../../services/LayoutsService';
import {
  LocalizationService,
  LocalizationTranslatorService
} from '../../services/localizationService';
import { MicrofrontendRouterService } from '../../services/mfeRouterService';
import { SplunkRumService } from '../../services/monitoringService';
import { NavigationService } from '../../services/navigationService';
import { PortalOnboardingService } from '../../services/PoralOnboardingService';
import { RoutesService } from '../../services/RoutesService';
import { ScopeService } from '../../services/scope';
import { ServiceWorkerService } from '../../services/serviceWorkerService';
import {
  SessionServiceNative,
  SessionServiceWeb
} from '../../services/session';
import {
  LoginServiceNative,
  LoginServiceWeb
} from '../../services/session/loginService';
import {
  OnecloudLogoutService,
  ShellLogoutService,
  StratusLogoutService
} from '../../services/session/logoutService';
import RefreshTokenServiceNative from '../../services/session/refreshTokenService/RefreshTokenServiceNative';
import RefreshTokenServiceWeb from '../../services/session/refreshTokenService/RefreshTokenServiceWeb';
import StateParamHandlerService from '../../services/stateParamHandlerService/StateParamHandlerService';
import {
  ISupportSessionService,
  SupportSessionService
} from '../../services/supportSession';
import { TenantHandlerService } from '../../services/tenantHandler';
import { ThemeService } from '../../services/themeService';
import { URLService } from '../../services/URLService';
import {
  OnecloudUserOnboardingService,
  StratusUserOnboardingService
} from '../../services/userOnboardingService';
import { StratusUserService } from '../../services/userService';
import OnecloudUserService from '../../services/userService/OnecloudUserService';
import WebServiceRouting from '../../services/webServiceRouting';
import { RepositoriesType } from './RepositoriesType';
import { ServicesType } from './ServicesType';
import { CommonsInitializeType, SetServiceDependencies } from './types';
import { LoggerService } from '../../services/loggerService';

export type ServicesSetDependenciesParams = {
  repositories: RepositoriesType;
};

export default class ServicesInitializer {
  public static async instantiate(
    params: CommonsInitializeType
  ): Promise<ServicesType> {
    const {
      abTesting,
      jWebPlatform,
      stack,
      clientId,
      appName,
      localization,
      analytics,
      consent,
      isNative,
      theme,
      login,
      logger,
      defaultLayoutKey,
      backgroundTasks,
      routes,
      featureFlags,
      monitoring,
      serviceWorker,
      userActivity,
      idleLogout,
      urlService,
      grants,
      grantsHistory,
      layouts,
      graphql,
      applicationContext,
      userOnboarding
    } = params;

    const services = {} as ServicesType;
    const isShellPortal = !!clientId;

    // Contextless services

    if (graphql?.platform && graphql.platform !== GraphQLPlatform.WEB) {
      services.graphQLService = new NativeGraphQLService(graphql.platform);
    } else if (
      isShellPortal ||
      graphql?.platform === GraphQLPlatform.WEB ||
      jWebPlatform === GraphQLPlatform.WEB
    ) {
      services.graphQLService = new GraphQLService(graphql);
    } else {
      services.graphQLService = new NativeGraphQLService(undefined);
    }

    services.URLService = new URLService(urlService);

    services.layoutsService = new LayoutsService({
      defaultLayoutKey,
      layoutList: layouts
    });

    if (isNative) {
      services.eventService = container.resolve(NativeEventService);
      await (services.eventService as NativeEventService).init();
    } else {
      services.eventService = container.resolve(WebEventService);
    }

    services.appContext = new AppContext();

    services.authProviderService = new AuthProviderService();

    services.tenantHandlerService = container.resolve(TenantHandlerService);

    // SUPPORT SESSION INITIALIZER
    const supportSessionService: ISupportSessionService = container.resolve(
      SupportSessionService
    );

    services.supportSessionService = supportSessionService;

    services.abTestingService = new ABTestingService(abTesting);

    services.authTokenService = container.resolve(AuthTokenService);

    services.breadcrumbService = new BreadcrumbService();

    services.backgroundTaskService = new BackgroundTaskService();
    services.assetLoaderService = new SystemJSAssetLoaderService();

    services.backgroundTaskManagerService = new BackgroundTaskManagerService(
      backgroundTasks
    );

    services.encryptService = new WebCryptoApiEncryptService();

    services.localizationService = new LocalizationService({
      localization
    });

    services.localizationTranslatorService = new LocalizationTranslatorService({
      localization
    });

    services.localizationTranslatorService = new LocalizationTranslatorService({
      localization
    });

    services.loggerService = new LoggerService(logger);

    services.featureFlagService = new LaunchDarklyFeatureFlagService(
      featureFlags
    );

    services.monitoringService = new SplunkRumService(monitoring);
    services.navigationService = container.resolve(NavigationService);

    services.routesService = new RoutesService(routes);

    services.scopeService = new ScopeService();

    services.themeService = new ThemeService(theme);

    services.consentService = new ConsentService(consent);

    if (login.authenticationProvider === AuthenticationProviderEnum.coptor) {
      services.userService = new OnecloudUserService();
    } else {
      services.userService = new StratusUserService();
    }

    services.webServiceRouting = new WebServiceRouting();

    if (login.authenticationProvider === AuthenticationProviderEnum.coptor) {
      // Onecloud only services
      services.applicationService = new OnecloudApplicationService({
        portalStack: stack,
        clientId,
        appName,
        applicationContext
      });

      services.userOnboardingService = new OnecloudUserOnboardingService(
        userOnboarding
      );
    } else {
      // Stratus only Services
      services.applicationService = new StratusApplicationService({
        portalStack: stack,
        clientId,
        appName,
        applicationContext
      });
      services.userOnboardingService = new StratusUserOnboardingService(
        userOnboarding
      );
    }

    container.registerInstance(
      'ApplicationService',
      services.applicationService
    );

    // TODO: Check if it's native.
    if (isNative) {
      services.loginService = new LoginServiceNative();
      services.sessionService = container.resolve(SessionServiceNative);
    } else {
      services.loginService = new LoginServiceWeb();
      services.sessionService = container.resolve(SessionServiceWeb);
    }

    // Analytics Service
    if (analytics.enabled) {
      services.analyticsService = new AnalyticsService(analytics);
    }

    // Logout Service
    if (services.supportSessionService?.isSupportSession()) {
      services.logoutService = new ShellLogoutService();
    } else if (
      login.authenticationProvider === AuthenticationProviderEnum.coptor
    ) {
      services.logoutService = new OnecloudLogoutService();
    } else {
      services.logoutService = new StratusLogoutService();
    }

    services.mfeRouterService = new MicrofrontendRouterService();
    services.stateParamHandlerService = new StateParamHandlerService();
    services.serviceWorkerService = new ServiceWorkerService(serviceWorker);
    services.idleService = new IdleService(userActivity);
    services.idleLogoutService = new IdleLogoutService(idleLogout);
    services.grantService = new GrantService(grants);
    services.grantHistoryService = new GrantHistoryService(grantsHistory);
    services.portalOnboardingService = new PortalOnboardingService();
    return services;
  }

  public static async resolveDependencies({
    services,
    repositories,
    clients
  }: SetServiceDependencies): Promise<ServicesType> {
    const defaultDependencies: SetServiceDependencies = {
      services: services,
      repositories,
      clients
    };

    services?.analyticsService?.setDependencies(defaultDependencies);
    services?.authProviderService?.setDependencies(defaultDependencies);

    services?.breadcrumbService?.setDependencies(defaultDependencies);
    services?.featureFlagService?.setDependencies(defaultDependencies);
    services?.scopeService?.setDependencies(defaultDependencies);
    services?.sessionService?.setDependencies(defaultDependencies);
    services?.mfeRouterService?.setDependencies(defaultDependencies);
    services?.loginService?.setDependencies(defaultDependencies);
    services?.logoutService?.setDependencies(defaultDependencies);
    services?.tenantHandlerService?.setDependencies(defaultDependencies);
    services?.userOnboardingService?.setDependencies(defaultDependencies);
    services?.userService?.setDependencies(defaultDependencies);
    services?.webServiceRouting?.setDependencies(defaultDependencies);
    services?.consentService?.setDependencies(defaultDependencies);
    services?.localizationTranslatorService?.setDependencies(
      defaultDependencies
    );
    services?.localizationService?.setDependencies(defaultDependencies);
    services?.loggerService?.setDependencies(defaultDependencies);
    services?.navigationService?.setDependencies(defaultDependencies);
    services?.serviceWorkerService?.setDependencies(defaultDependencies);
    services?.backgroundTaskManagerService?.setDependencies(
      defaultDependencies
    );
    services?.grantService?.setDependencies(defaultDependencies);
    services?.grantHistoryService?.setDependencies(defaultDependencies);
    services?.stateParamHandlerService?.setDependencies(defaultDependencies);
    services?.portalOnboardingService?.setDependencies(defaultDependencies);

    return services;
  }

  // It only registers the default resource in case there is no custom override.
  private static _registerIfNotOverriden(key: string, service): void {
    if (!container.isRegistered(key)) {
      container.registerSingleton(key, service);
    }
  }

  public static registerOverridableSingletons(): void {
    this._registerIfNotOverriden(
      'IGetAccessTokenService',
      GetAccessTokenService
    );
    if (!container.isRegistered('IRefreshTokenService')) {
      container.register('IRefreshTokenService', {
        useFactory: () => {
          const isNative = container.resolve('IsNative');
          return isNative
            ? container.resolve(RefreshTokenServiceNative)
            : container.resolve(RefreshTokenServiceWeb);
        }
      });
    }
  }
}
