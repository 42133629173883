import { NewLoggerInterfaceType, LogMethodsType } from './types';

type noopLoggerInterfaceParam = {
  logCallback?: () => void;
};

export const createNoopNewLoggerInterface = ({
  logCallback
}: noopLoggerInterfaceParam): NewLoggerInterfaceType => {
  const newLoggerNoopInterface: NewLoggerInterfaceType = {
    log: logCallback as unknown as LogMethodsType,
    debug: logCallback as unknown as LogMethodsType,
    warn: logCallback as unknown as LogMethodsType,
    error: logCallback as unknown as LogMethodsType
  };
  return newLoggerNoopInterface;
};
