export const defaultLogParams = [
  'timestamp',
  'title',
  'level',
  'serviceName',
  'portalName',
  'message'
];

export const defaultDebugParams = [
  'version',
  'environment',
  'authenticationProvider',
  'stack',
  'className',
  'methodName'
];
