import { logDictionary } from '../Dictionaries/logDictionary';
import { GenericLogMethod, ILevelLogClass, LevelLogProps } from '../types';
import { genericLogMethod } from '../utils/genericLogMethod';

class InfoLogger implements ILevelLogClass {
  private _infoLogs: Record<string, GenericLogMethod> = {};

  constructor(loggerProps: LevelLogProps) {
    logDictionary.forEach((log) => {
      const _method = genericLogMethod({
        ...log,
        loggerProps
      });
      this._infoLogs[log.id] = _method;
    });
  }

  getLogs(): Record<string, GenericLogMethod> {
    return this._infoLogs;
  }
}
export default InfoLogger;
