import { defaultLogParams } from './defaultLogParams';

export const logDictionary = [
  {
    id: 'l1',
    title: 'Successfully initialized',
    level: 'log',
    params: defaultLogParams
  },
  {
    id: 'l2',
    title: 'Task Completed Successfully',
    level: 'log',
    params: [...defaultLogParams, 'taskName', 'taskId', 'status']
  },
  {
    id: 'l3',
    title: 'Subscribed to New Event Channel',
    level: 'log',
    params: [...defaultLogParams, 'channelName']
  },
  {
    id: 'l4',
    title: 'Event Published Successfully',
    level: 'log',
    params: [...defaultLogParams, 'eventType', 'channelName']
  },
  {
    id: 'l5',
    title: 'Listener Initialized',
    level: 'log',
    params: [...defaultLogParams, 'listenerName']
  },
  {
    id: 'l6',
    title: 'Event Received Successfully',
    level: 'log',
    params: [...defaultLogParams, 'eventType', 'source']
  },
  {
    id: 'l7',
    title: 'User Action Tracked',
    level: 'log',
    params: [...defaultLogParams, 'userId', 'action']
  }
];
