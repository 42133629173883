import { defaultDebugParams, defaultLogParams } from './defaultLogParams';

export const debugDictionary = [
  {
    id: 'd1',
    title: 'Variable State Changed',
    level: 'debug',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'variableName',
      'currentValue'
    ]
  },
  {
    id: 'd2',
    title: 'Method Execution Started',
    level: 'debug',
    params: [...defaultLogParams, ...defaultDebugParams, 'parameters']
  },
  {
    id: 'd3',
    title: 'Method Execution Completed',
    level: 'debug',
    params: [...defaultLogParams, ...defaultDebugParams, 'returnValue']
  },
  {
    id: 'd4',
    title: 'Critical Path Execution Started',
    level: 'debug',
    params: [...defaultLogParams, ...defaultDebugParams, 'variables']
  },
  {
    id: 'd5',
    title: 'Configuration Loaded',
    level: 'debug',
    params: [...defaultLogParams, ...defaultDebugParams]
  },
  {
    id: 'd6',
    title: 'Third-Party Library Called',
    level: 'debug',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'libraryName',
      'parameters'
    ]
  },
  {
    id: 'd7',
    title: 'API Request and Response Tracked',
    level: 'debug',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'endpoint',
      'requestParams',
      'response',
      'status'
    ]
  }
];
