import getDecodedURLStateParam, {
  DecodedStateParamType
} from '../../../../utils/getDecodedURLStateParam';
import StageBuilder from '../../stageBuilder/StageBuilder';
import { internalLogger } from '../../../../interface/v1/logger';
import * as T from './types';
import LoggedinErrorHandler from './handlers/LoggedinErrorHandler';
import SkipUserOnboardingHandler from './handlers/SkipUserOnboardingHandler';
import ShellUserOnboardingHandler from './handlers/ShellUserOnboardingHandler';
import PortalCustomUserOnboardingHandler from './handlers/PortalCustomUserOnboardingHandler';
import InvitationFlowHandler from './handlers/InvitationFlowHandler';
import DefaultHandler from './handlers/DefaultHandler';
import { getServices } from '../../../../infra/commonInitializer';
import { INavigationService } from '../../../../services/navigationService';
import { IUserOnboardingService } from '../../../../services/userOnboardingService';
import { ISessionService } from '../../../../services/session';
import { ILocalizationService } from '../../../../services/localizationService';
import { StateParamHandlerService } from '../../../stateParamHandlerService';
import { ILoginService } from '../../../../services/session/loginService';

export default class LoggedInPathOperation
  implements T.RouterOperationInterface
{
  private sessionService: ISessionService;
  private loginService: ILoginService;
  private userOnboardingService: IUserOnboardingService;
  private navigationService: INavigationService;
  private localizationService: ILocalizationService;
  private criterionInterface: T.LoggedInOperationDependenciesType['criterionInterface'];
  private onboardingData: T.LoggedInOperationDependenciesType['onboardingData'];
  private userOnboardingData: T.LoggedInOperationDependenciesType['userOnboardingData'];
  private loginData: T.LoggedInOperationDependenciesType['loginData'];
  private basePath: T.LoggedInOperationDependenciesType['basePath'];

  constructor(dependencies: T.LoggedInOperationDependenciesType) {
    this.criterionInterface = dependencies.criterionInterface;
    this.onboardingData = dependencies.onboardingData;
    this.userOnboardingData = dependencies.userOnboardingData;
    this.loginData = dependencies.loginData;
    this.basePath = dependencies.basePath;

    const {
      sessionService,
      userOnboardingService,
      localizationService,
      navigationService,
      loginService
    } = getServices();

    this.sessionService = sessionService;
    this.userOnboardingService = userOnboardingService;
    this.localizationService = localizationService;
    this.navigationService = navigationService;
    this.loginService = loginService;
  }

  private proceedToShell(
    manifest: T.LoggedInManifestType,
    state: DecodedStateParamType,
    additionalParams?: URLSearchParams
  ): Promise<void> {
    const postLoginRedirectPath = StateParamHandlerService.getPostLoginRedirect(
      state,
      manifest.portal.basePath
    );

    const redirectionUrl = new URL(
      `${window.location.origin}${postLoginRedirectPath}`
    );

    if (additionalParams) {
      additionalParams.forEach((value, key) => {
        redirectionUrl.searchParams.append(key, value);
      });
    }

    this.navigationService?.redirect(redirectionUrl.toString());
    return Promise.resolve();
    //return new Promise<void>(() => undefined);
  }

  isUserInLoggedinPath(): boolean {
    if (this.loginService.isLoggedinPath()) {
      /* istanbul ignore next */
      internalLogger?.debug?.('User is navigating to /loggedin path.');
      return true;
    }
    return false;
  }

  isLoginDataEnabled(): boolean {
    const isLoginDataEnabled = this.loginData?.enabled === true;
    if (isLoginDataEnabled) {
      return true;
    }
    return false;
  }

  async process(stateBuilder: StageBuilder): Promise<void> {
    // Special condition to this operation

    if (!this.isUserInLoggedinPath()) return;

    if (!this.isLoginDataEnabled()) return;

    const decodedState = getDecodedURLStateParam();
    const isUserOnboarded = await this.isUserAlreadyOnboarded();

    const manifest: T.LoggedInManifestType = {
      portal: {
        basePath: this.basePath
      },
      services: {
        login: this.loginData,
        onboarding: this.onboardingData,
        userOnboarding: this.userOnboardingData
      }
    };

    const interfaces: T.LoggedInInterfaceType = {
      criterionInterface: this.criterionInterface
    };

    const services: T.LoggedInServiceType = {
      localizationService: this.localizationService,
      navigationService: this.navigationService,
      userOnboardingService: this.userOnboardingService
    };

    const handlerParams: T.LoggedInHandleParamsType = {
      decodedState,
      isUserOnboarded,
      manifest,
      interfaces,
      stateBuilder,
      services,
      proceedToShell: this.proceedToShell.bind(this)
    };

    const loggedinErrorHandler = new LoggedinErrorHandler();
    const skipUserOnboardingHandler = new SkipUserOnboardingHandler();
    const portalCustomUserOnboardingHandler =
      new PortalCustomUserOnboardingHandler();
    const shellUserOnboardingHandler = new ShellUserOnboardingHandler();
    const invitationFlowHandler = new InvitationFlowHandler();
    const defaultHandler = new DefaultHandler();
    // Chain the handlers
    loggedinErrorHandler
      .setNext(skipUserOnboardingHandler)
      .setNext(portalCustomUserOnboardingHandler)
      .setNext(shellUserOnboardingHandler)
      .setNext(invitationFlowHandler)
      .setNext(defaultHandler);

    // Run the chain
    await loggedinErrorHandler.handle(handlerParams);

    // At this point, we must end the Process Chain.
    stateBuilder.setEndProcessChain(true);
  }

  async isUserAlreadyOnboarded(): Promise<boolean> {
    return this.sessionService.isLoggedIn()
      ? await this.userOnboardingService.isUserOnboarded()
      : false;
  }
}
