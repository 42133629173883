import { JWebType } from '../../services/JWeb/types';
import TenantObserver, {
  TenantEvents
} from '../../services/tenantHandler/TenantObserver';
import { IUserService } from '../../services/userService';

export type SyncAuthenticationDataOnJWebValueStoreType = {
  JWeb: JWebType;
  userService: IUserService;
};

export default class SyncAuthenticationDataOnJWebValueStore {
  private _JWeb: JWebType;
  private _userService: IUserService;

  constructor({
    JWeb,
    userService
  }: SyncAuthenticationDataOnJWebValueStoreType) {
    this._JWeb = JWeb;
    this._userService = userService;
  }

  public async init(): Promise<void> {
    this._setAuthenticationDataOnJWebValueStore();
    TenantObserver.subscribe(
      TenantEvents.SET_TENANT,
      this._setAuthenticationDataOnJWebValueStore
    );
  }

  private _setAuthenticationDataOnJWebValueStore = () => {
    const authenticationData = this._userService.getAuthenticationData();
    this._JWeb.Plugins.ValueStore.put({
      entries: [
        {
          key: 'authenticationData',
          value: JSON.stringify(authenticationData)
        }
      ]
    });
  };
}
